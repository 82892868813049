import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/monkey/Projects/bkc-sajt/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Caption = makeShortcode("Caption");
const Row = makeShortcode("Row");
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>Od 1959.godine, u Gračanici postoji i djeluje Narodni univerzitet kao profesionalna društvena institucija za obrazovanje odraslih, kulturu i informisanje.Od tada vrlo aktivno djeluje u oblasti obrazovanja odraslih: obrazovanje za najrazličitija zanatska zanimanja, obrazovanja zemljoradnika, zdravstveno prosjećivanje i obuku domaćica pa do osnovne škole za obrazovanje odraslih, srednjih i viših škola raznih profila.</p>
    </PageDescription>
    <div {...{
      "className": "gatsby-resp-iframe-wrapper",
      "style": {
        "paddingBottom": "56.25%",
        "position": "relative",
        "height": "0",
        "overflow": "hidden"
      }
    }}>{` `}<row parentName="div">{` `}<iframe parentName="row" {...{
          "src": "https://www.youtube.com/embed/Y3ssrEWV7Ds",
          "frameBorder": "0",
          "allow": "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
          "allowFullScreen": true,
          "style": {
            "position": "absolute",
            "top": "0",
            "left": "0",
            "width": "100%",
            "height": "100%"
          }
        }}></iframe>{` `}</row>{` `}</div>
    <Caption mdxType="Caption">
Youtube video, datum objave: 6 Maj, 2013, autor: Fahrudin Kurtović
    </Caption>
    <Row mdxType="Row">
      <p>
        <br />
        <br />
Narodni univerzitet je suvremeno i kvalitetno objedinjavao opće obrazovnu i opće kulturnu aktivnost.U njegovom sastavu bile su sljedeće institucije: Centar za obrazovanje odraslih i selo, Biblioteka dr.Mustafa Kamarić,Radio Gračanica, Kino i Centar za kulturu,kasnije Dom kulture.Do preseljenja u novi Dom kulture 1978.i 1979.godine,Dom kulture koristio je prostor zgrade medrese,dio nekadašnjeg Partizana i staro kino.
      </p>
    </Row>
    <InlineNotification mdxType="InlineNotification">
Pretrpivši mnoge organizacione promjene 1992.godine transformirao se u Bosanski kulturni cventar u sljedećim oblicima rada: Dom kulture- kulturna djelatnost.biblioteka,radio stanica i kino.
    </InlineNotification>
    <Row mdxType="Row">
      <p><strong>Tokom prvih deset godina Sarajevo film festivala, gračaničko Kino jedino je u Bosni i Hercegovini svake godine organiziralo reviju festivalskih filmskih projekcija. Kasne devedesete godine obilježavale su bosansko-hercegovačke premijere svjetskih filmskih klasika i pune kino dvorane što će se zvati zlatnom erom gračaničkog kina. Danas kao i drugdje oko nas, s razvojem filmske i televizijske tehnologije Kino Gračanica živi na svom rubu preživljavajući uz pomoć entuzijasta i sreću pravih zaljubljenika u filmsko platno.</strong></p>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      